import { brandConfig } from '@hubcms/brand';
import { type TeaserFragment, createTeaserFragment } from '@hubcms/domain-cook';
import { type MySectionGroupOptions, type StoryblockAreas } from '@hubcms/domain-storyblock';
import { type TeaserDataOrList, type TeaserPropsOptions } from '@hubcms/domain-teaser';
import { error } from '@hubcms/utils-monitoring';
import { mapTeaserFragments } from '@hubcms/utils-teaser';

const MY_SECTION_DATA_ID = 'my-section-data';

type SectionUniqueName = string;
type MySectionData = Record<
  SectionUniqueName,
  {
    overviewSectionHref: string;
    selectedSectionHref: string;
    selectedSectionName: string;
    latestArticles: Array<TeaserFragment['content']>;
  }
>;

function getThisSectionData(overviewSectionHref: string) {
  try {
    const mySectionDataElement = document.getElementById(MY_SECTION_DATA_ID);
    const mySectionDataTextContent = mySectionDataElement?.textContent ?? '{}';
    const mySectionData = JSON.parse(mySectionDataTextContent) as MySectionData;
    return Object.values(mySectionData).find(sectionData => sectionData.overviewSectionHref === overviewSectionHref) ?? null;
  } catch (e) {
    error('Error parsing mySectionData JSON:', e as never);
    return null;
  }
}

export function replaceDeskedTeasersWithMySectionData(
  mySectionGroupOptions: MySectionGroupOptions,
  deskedTeaserAreas: StoryblockAreas<TeaserDataOrList>,
  teaserPropsOptions: TeaserPropsOptions,
) {
  if (!brandConfig.enableMySection || typeof window === 'undefined') {
    return {};
  }

  if (!mySectionGroupOptions.overviewSectionLink) {
    return {};
  }

  const thisSectionData = getThisSectionData(mySectionGroupOptions.overviewSectionLink);
  if (!thisSectionData) {
    return {};
  }

  const latestArticlesTeaserFragments = thisSectionData.latestArticles.map(content => createTeaserFragment({ content }));

  return {
    newTeaserAreas: {
      ...deskedTeaserAreas,
      groupArea: mapTeaserFragments(latestArticlesTeaserFragments, teaserPropsOptions),
    },
    newGroupOptions: {
      selectedSection: {
        link: thisSectionData.selectedSectionHref,
        name: thisSectionData.selectedSectionName,
      },
    },
  };
}
