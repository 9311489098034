import {
  type AdditionalTeaserContent,
  isAdditionalTeaserContent,
  isArticleTeaserContent,
  isRealTeaserContent,
  isTagIntroTeaserContent,
  type RealTeaserContent,
  type TeaserFragment,
} from '@hubcms/domain-cook';
import type { TeaserImage } from '@hubcms/domain-teaser';

import { type CookImageData, createTeaserImageFromCook } from './createTeaserImageFromCook';

export function getTeaserImage(teaserFragment: TeaserFragment): TeaserImage | null {
  const cookImageAndCaption = getCookImageAndCaption(teaserFragment);
  if (!cookImageAndCaption || !cookImageAndCaption.image.content) {
    return null;
  }
  return createTeaserImageFromCook(cookImageAndCaption.image, cookImageAndCaption.caption);
}

type ContentWithTeaserImage = RealTeaserContent | AdditionalTeaserContent;

function isContentWithTeaserImage(content: TeaserFragment['content']): content is ContentWithTeaserImage {
  return isRealTeaserContent(content) || isAdditionalTeaserContent(content);
}

function getCookImageAndCaption(teaserFragment: TeaserFragment): {
  image: CookImageData;
  caption: string | null;
} | null {
  const deskedImage = teaserFragment.deskedImage?.[0];

  const teaserImage = isContentWithTeaserImage(teaserFragment.content) ? teaserFragment.content.teaserImage?.[0] : null;
  const firstImage = isTagIntroTeaserContent(teaserFragment.content) ? teaserFragment.content.images?.[0] : null;

  const image = deskedImage || teaserImage || firstImage;

  if (isCookImageData(image)) {
    const caption = teaserImage?.caption || teaserImage?.content?.fields.caption || null;
    return {
      image,
      caption,
    };
  }

  if (isArticleTeaserContent(teaserFragment.content)) {
    const heroMedia = teaserFragment.content.heroMedia?.[0];
    const heroMediaQuickCaptionObj = heroMedia?.fields?.filter(el => el.name === 'caption')?.pop();
    const heroMediaQuickCaption = heroMediaQuickCaptionObj?.value;

    switch (heroMedia?.content?.__typename) {
      case 'Picture':
        return {
          image: heroMedia as CookImageData,
          caption: heroMediaQuickCaption || heroMedia.content.fields?.caption || null,
        };
      case 'Gallery':
        return heroMedia.content.relatedImages?.[0]
          ? {
              image: heroMedia.content.relatedImages[0],
              caption: heroMediaQuickCaption || heroMedia.content.fields?.title || null,
            }
          : null;
      default:
        break;
    }
  }
  return null;
}

type PartialCookImageData = Omit<CookImageData, 'content'> & { content: CookImageData['content'] | null };
function isCookImageData(imageCandidate: PartialCookImageData | null): imageCandidate is CookImageData {
  return !!imageCandidate && !!imageCandidate.content;
}
