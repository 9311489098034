import type { ImageFieldsFragment, PictureRelationFragment } from '@hubcms/domain-cook';
import type { TeaserImage, TeaserImageSource } from '@hubcms/domain-teaser';

export type CookImageData = {
  caption?: string;
  href?: string;
  content: PictureRelationFragment;
};

export function createTeaserImageFromCook(cookImage: CookImageData, caption: string | null): TeaserImage | null {
  return {
    caption,
    imageFormats: createImageFormats(cookImage.content.fields),
  };
}

function createImageFormats(fields: NonNullable<CookImageData['content']>['fields']): TeaserImage['imageFormats'] {
  return {
    fourFive: createImageFormat(fields.fourFive),
    fourThree: createImageFormat(fields.fourThree),
    oneOne: createImageFormat(fields.oneOne),
    sixteenNine: createImageFormat(fields.sixteenNine),
    threeTwo: createImageFormat(fields.threeTwo),
    twentyoneNine: createImageFormat(fields.twentyoneNine),
  };
}

function createImageFormat(representation: ImageFieldsFragment): TeaserImageSource {
  return {
    height: representation.height,
    width: representation.width,
    url: representation.href_full,
  };
}
