import type { ContentType } from '@hubcms/domain-cook';
import type { TrackingData } from '@hubcms/domain-tracking';

import type { PartialRequiredTeaser } from '../util-types/partial-required-teaser';

import type { TeaserImage, TeaserImageSource } from './teaser-image';

type TeaserAuthor = {
  image: TeaserImageSource | null;
  name: string;
};

export type ArticleTeaserData = {
  teaserType: 'article';
  contentType: ContentType | '';
  authors: TeaserAuthor[];
  dateTime: string;
  customImageMobile: TeaserImage | null;
  customImageDesktop: TeaserImage | null;
  duration: string;
  hasHeroVideo: boolean;
  canHideExternalIcon: boolean;
  id: string;
  image: TeaserImage | string | null;
  introduction: string;
  isDateToday: boolean;
  isExternal: boolean;
  isPremium: boolean;
  label: string;
  list?: {
    id: string;
    sourceType: string;
  };
  prefix: string;
  subLabel: string;
  title: string;
  theme?: string;
  url: string;
  trackingData?: TrackingData;
  teaserLabel: string;
  teaserShowLiveIcon: boolean;
  videoPreviewId?: string;
};

export function createArticleTeaserData({
  authors = [],
  customImageDesktop = null,
  customImageMobile = null,
  dateTime = '',
  duration = '',
  hasHeroVideo = false,
  canHideExternalIcon = false,
  image = null,
  introduction = '',
  isDateToday = false,
  isExternal = false,
  isPremium = false,
  label = '',
  prefix = '',
  subLabel = '',
  theme = 'none',
  trackingData = {},
  teaserLabel = '',
  teaserShowLiveIcon = false,
  ...optionalProps
}: PartialRequiredTeaser<ArticleTeaserData, 'contentType' | 'id' | 'title' | 'url'>): ArticleTeaserData {
  return {
    teaserType: 'article',
    authors,
    customImageDesktop,
    customImageMobile,
    dateTime,
    duration,
    hasHeroVideo,
    canHideExternalIcon,
    image,
    introduction,
    isDateToday,
    isExternal,
    isPremium,
    label,
    prefix,
    subLabel,
    theme,
    trackingData,
    teaserLabel,
    teaserShowLiveIcon,
    ...optionalProps,
  };
}
