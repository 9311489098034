import { brandConfig } from '@hubcms/brand';
import { error } from '@hubcms/utils-monitoring';

export function getGroupLink<T extends Record<string, string>, TL extends keyof T, SL extends keyof T, FL extends keyof T>(
  options: T,
  tagLink: TL,
  sectionLink: SL,
  freeLink?: FL,
) {
  if (!brandConfig.tagsPrefix || !brandConfig.tagsb2bPrefix) {
    error("Can't determine tag prefix");
  }

  if (freeLink && options[freeLink]) {
    return options[freeLink];
  }
  if (options[tagLink]) {
    const tagName = (options[tagLink] as string).split(':').pop();
    if (tagName?.startsWith(brandConfig.tagsPrefix) || tagName?.startsWith(brandConfig.tagsb2bPrefix)) {
      return `/${tagName}`;
    }
    return `/${brandConfig.tagsPrefix}${tagName}`;
  }
  if (options[sectionLink]) {
    return options[sectionLink];
  }
  return '';
}
