import type { TeaserFragment } from '@hubcms/domain-cook';
import type { TeaserImage } from '@hubcms/domain-teaser';

import { createTeaserImageFromCook } from './createTeaserImageFromCook';

type CustomImages = Record<'customImageDesktop' | 'customImageMobile', TeaserImage | null>;

export function getCustomImages(teaserFragment: TeaserFragment, customCaption: string): CustomImages {
  const cookImageDesktop = teaserFragment.deskedImageDesktop?.[0];
  const cookImageMobile = teaserFragment.deskedImageMobile?.[0];

  return {
    customImageDesktop: cookImageDesktop?.content ? createTeaserImageFromCook(cookImageDesktop, customCaption) : null,
    customImageMobile: cookImageMobile?.content ? createTeaserImageFromCook(cookImageMobile, customCaption) : null,
  };
}
