/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { brandConfig, storyblockTeaserSizesConfig } from '@hubcms/brand';
import type { ImageSizes } from '@hubcms/domain-images';
import type { RegularGroupOptions } from '@hubcms/domain-storyblock';
import {
  isArticleTeaserData,
  isHtmlTeaserData,
  isNewsletterTeaserData,
  isPlaceholderTeaserData,
  isRssListTeaserData,
} from '@hubcms/domain-teaser';
import { type TGridProps, createTitle, type GGrid } from '@hubcms/domain-teaser-grid';

import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';
import { createAdItems } from '../createAdItems';
import { createHtmlGridItem } from '../createHtmlGridItem';
import { createNewsletterItem } from '../createNewsletterItem';
import { createRssListGridItem } from '../createRssListGridItem';
import { createTeaserForGroup } from '../createTeaserForGroup';
import { getTeaserSize } from '../getTeaserSize';

const getRegularTeaserSizes = getTeaserSize(storyblockTeaserSizesConfig, 'regular');

// Remove Extract of 'textOnImage' in CW-2612
function parseImageFormat(
  teaserType: Extract<RegularGroupOptions['teaserType'], 'textOnImageOneOne' | 'textOnImageFourFive' | 'textOnImage'>,
) {
  if (teaserType === 'textOnImageOneOne') {
    return 'oneOne';
  }
  // Also serves as a safe-ish fallback in case CUE returns a teaserType w/ an image format we don't support yet
  return 'fourFive';
}

export const mapRegularGroup: MapStoryblockFn<RegularGroupOptions> = (storyblockOptions, teaserAreas) => {
  const isTextOnImageTeaser =
    storyblockOptions.teaserType === 'textOnImageOneOne' ||
    storyblockOptions.teaserType === 'textOnImageFourFive' ||
    storyblockOptions.teaserType === 'textOnImage'; // Remove this line in CW-2612

  const title = createTitle(storyblockOptions.title, storyblockOptions.actionLink, storyblockOptions.actionText);
  const numberOfTeasersWhenNoAds = storyblockOptions.nrOfItems;
  const numberOfTeasersWhenAds: Record<RegularGroupOptions['nrOfItems'], number> = {
    3: 2,
    4: 3,
    6: 4,
    8: 6,
  };
  const isThreeColumnLg = [3, 6].includes(numberOfTeasersWhenNoAds);
  const isCarouselMobile = storyblockOptions.mobileLayout.carousel;
  const hasSliderSm = !storyblockOptions.mobileLayout.isStacked;
  const hasSliderMd = isThreeColumnLg;

  const adStartLg = title ? 2 : 1;

  const { hasAd, isCbaAd, isAdDisplayedOnFirstPosition, adFormats, adGridProps } = getAdvertisementProps({
    storyblockOptions,
    adStartLg,
    hasSliderSm,
    hasSliderMd,
  });

  const numberOfTeasers = (hasAd ? numberOfTeasersWhenAds[numberOfTeasersWhenNoAds] : numberOfTeasersWhenNoAds) || 4;

  const defaultMinWidth = isThreeColumnLg
    ? 'var(--x-regular-storyblock-group-3n-teaser-grid-item-min-width, 280px)'
    : 'var(--x-regular-storyblock-group-4n-teaser-grid-item-min-width, 240px)';

  const carouselWidth = {
    sm: 'calc(100vw - var(--i-teaser-grid-column-gap) * 2)',
    md: 'auto',
    lg: defaultMinWidth,
  };
  const minWidth = isCarouselMobile ? carouselWidth : defaultMinWidth;

  const items: GGrid['data']['items'] = teaserAreas.groupArea.slice(0, numberOfTeasers).map((teaserData, idx, arr) => {
    const isHeadTeaser = idx === 0;
    const isHeroTeaser = isHeadTeaser && storyblockOptions.mobileLayout.hasHero;
    if (isNewsletterTeaserData(teaserData)) {
      return createNewsletterItem(teaserData);
    }

    if (isHtmlTeaserData(teaserData)) {
      const gridItem = createHtmlGridItem(teaserData);
      gridItem.gridProps = {
        ...gridItem.gridProps,
        minWidth,
      };
      return gridItem;
    }

    if (isRssListTeaserData(teaserData)) {
      return createRssListGridItem({
        teaserData,
        storyblockTitle: storyblockOptions.title,
      });
    }
    const isPlaceholder = isPlaceholderTeaserData(teaserData);
    const isSponsoredTeaser = !isPlaceholder && teaserData.theme === 'sponsored-content';
    const hasSponsoredThemeOnTeaserLevel = !storyblockOptions.isSponsoredGroup && isSponsoredTeaser;
    const isHighlighted = hasSponsoredThemeOnTeaserLevel && brandConfig.sponsoredContentTeaserIsHighlighted;
    const hasInsetSm = isTextOnImageTeaser ? false : isHighlighted || isHeroTeaser;
    const hasInsetMd = isHighlighted;

    const baseImageSizes: ImageSizes = {
      xs: '100vw',
      md: '50vw',
      lg: '308px',
    };

    return createTeaserForGroup({
      gridProps: {
        minWidth,
        hasInverseMarginInlineSm: hasInsetSm,
        hasInverseMarginBlockEndSm: hasInsetSm,
        hasInverseMarginSm: isHighlighted,
        hasNoGridDivider: hasAd && idx === arr.length - 1,
      },
      hasDateTime: false,
      hasInset: isTextOnImageTeaser ? undefined : { xs: hasInsetSm, md: hasInsetMd },
      hasIntro: !storyblockOptions.isIntroHidden,
      hasLabel: true,
      imageFormat: storyblockOptions.teaserType === 'default' ? undefined : parseImageFormat(storyblockOptions.teaserType),
      imageSizes: isTextOnImageTeaser
        ? {
            ...baseImageSizes,
            sm: '100vw',
          }
        : baseImageSizes,
      orientation:
        storyblockOptions.teaserType === 'default'
          ? {
              xs: hasSliderSm || isHeroTeaser || isCarouselMobile ? 'vertical' : 'horizontal',
              md: 'vertical',
            }
          : undefined,
      size:
        storyblockOptions.teaserType === 'default'
          ? getRegularTeaserSizes(isHeroTeaser, hasSliderMd)
          : {
              xs: 'md',
              sm: 'sm',
            },
      teaserData,
      teaserType: isTextOnImageTeaser ? 'text-on-image-teaser' : 'default-teaser',
      trackingData: {
        ...(!isPlaceholder ? teaserData.trackingData : {}),
        clickitemblock: storyblockOptions.title,
        clickitemposition: idx + 1,
      },
      theme: hasSponsoredThemeOnTeaserLevel ? 'sponsored-content' : undefined,
      isHighlighted,
    });
  });
  const sponsoredItemIdx = teaserAreas.groupArea
    .slice(0, numberOfTeasers)
    .findIndex(item => isArticleTeaserData(item) && item.theme === 'sponsored-content');
  const sponsoredItem = hasSliderSm && !storyblockOptions.isSponsoredGroup ? items[sponsoredItemIdx] : null;
  if (sponsoredItem?.gridProps) {
    sponsoredItem.gridProps.display = 'none';
    sponsoredItem.gridProps.displayMd = 'block';
    sponsoredItem.gridProps.displayLg = 'block';
    sponsoredItem.gridProps.hasInverseMarginBlockEndSm = false;
  }

  const nrOfGridColumnsLg = isThreeColumnLg ? 3 : 4;
  const nrOfGridColumnsMd = isThreeColumnLg ? 3 : 2;
  const gridColumnsLgWithAd = isAdDisplayedOnFirstPosition
    ? `minmax(min-content, 1fr) repeat(${nrOfGridColumnsLg - 1}, 1fr)`
    : `repeat(${nrOfGridColumnsLg - 1}, 1fr) minmax(min-content, 1fr)`;
  const gridColumnsLg = hasAd ? gridColumnsLgWithAd : nrOfGridColumnsLg;
  const adItems = hasAd ? createAdItems(isCbaAd, adFormats, adGridProps[numberOfTeasersWhenNoAds]) : [];

  return {
    key: 'groupArea',
    title,
    gridColumnsLg,
    gridAutoRows: 'min-content',
    hasInlinePadding: {
      xs: true,
      sm: true,
      md: true,
      lg: false,
      xl: false,
    },
    hasNoGridDivider: hasSliderSm || hasSliderMd,
    items: (isCarouselMobile
      ? [
          {
            type: 'grid',
            data: {
              items,
              gridColumns: items.length,
              gridColumnsMd: nrOfGridColumnsMd,
              gridColumnsLg: hasAd ? nrOfGridColumnsLg - 1 : nrOfGridColumnsLg,
              gridAutoRows: 'min-content',
              hasInlinePadding: false,
              scroll: {
                breakpoints: {
                  xs: true,
                  sm: true,
                  md: false,
                  lg: false,
                  xl: false,
                },
                nrOfDots: items.length,
                snap: true,
              },
            },
            gridProps: {
              column: '1 / -1',
              columnLg: hasAd ? getColumnLg(isAdDisplayedOnFirstPosition) : '1 / -1',
            },
          } as GGrid,
        ]
      : hasSliderSm || hasSliderMd
        ? [
            createSliderGrid(items, {
              hasSliderSm,
              hasSliderMd,
              hasAd,
              isAdDisplayedOnFirstPosition,
              nrOfGridColumnsLg,
            }),
          ]
        : items
    )
      .concat(adItems)
      .concat(
        sponsoredItem
          ? [
              {
                ...sponsoredItem,
                gridProps: {
                  ...sponsoredItem.gridProps,
                  display: 'block',
                  displayMd: 'none',
                  displayLg: 'none',
                },
              },
            ]
          : [],
      ),
  };
};

function getColumnLg(isAdDisplayedOnFirstPosition: boolean) {
  return isAdDisplayedOnFirstPosition ? '2 / -1' : '1 / -2';
}

function getAdGridProps({
  isAdDisplayedOnFirstPosition,
  adStartLg,
  hasSliderSm,
  hasSliderMd,
}: {
  isAdDisplayedOnFirstPosition: boolean;
  adStartLg: 1 | 2;
  hasSliderSm: boolean;
  hasSliderMd: boolean;
}): Record<RegularGroupOptions['nrOfItems'], TGridProps> {
  return {
    3: {
      columnMd: '1 / 3',
      columnLg: isAdDisplayedOnFirstPosition ? '1 / 2' : '3 / 4',
      row: isAdDisplayedOnFirstPosition ? `${adStartLg}` : 'auto',
      minWidth: 'min-content',
    },
    4: hasSliderSm
      ? {
          columnMd: '1 / -1',
          columnLg: isAdDisplayedOnFirstPosition ? '1 / 2' : '4 / 5',
          rowLg: isAdDisplayedOnFirstPosition ? '2' : 'auto',
          minWidth: 'min-content',
        }
      : {
          column: isAdDisplayedOnFirstPosition ? '1 / -1' : 'auto',
          row: isAdDisplayedOnFirstPosition ? `${adStartLg}` : 'auto',
          columnMd: isAdDisplayedOnFirstPosition ? '1 / 2' : 'auto',
          minWidth: 'min-content',
        },
    6: {
      columnMd: '1 / 3',
      columnLg: isAdDisplayedOnFirstPosition ? '1 /  2' : '3 / 4',
      row: isAdDisplayedOnFirstPosition ? `${adStartLg}` : 'auto',
      rowLg: hasSliderSm || hasSliderMd ? `${adStartLg}` : `${adStartLg} / span 2`,
      minWidth: 'min-content',
    },
    8: {
      column: '1 / -1',
      row: isAdDisplayedOnFirstPosition ? `${adStartLg}` : 'auto',
      columnMd: '1 / -1',
      columnLg: isAdDisplayedOnFirstPosition ? '1 / 2' : '4 / 5',
      rowLg: hasSliderSm || hasSliderMd ? `${adStartLg}` : `${adStartLg} / span 2`,
      minWidth: 'min-content',
    },
  };
}

type AdvertisementProps = {
  hasAd: boolean;
  isCbaAd: boolean;
  isAdDisplayedOnFirstPosition: boolean;
  adFormats: string;
  adGridProps: Record<RegularGroupOptions['nrOfItems'], TGridProps>;
};

function getAdvertisementProps({
  storyblockOptions,
  adStartLg,
  hasSliderSm,
  hasSliderMd,
}: {
  storyblockOptions: RegularGroupOptions;
  adStartLg: 1 | 2;
  hasSliderSm: boolean;
  hasSliderMd: boolean;
}): AdvertisementProps {
  const hasAd = !!storyblockOptions.ads;
  const isCbaAd = storyblockOptions.ads?.type === 'cba';
  const isAdDisplayedOnFirstPosition = storyblockOptions.ads?.position === 'first';
  const adFormats = storyblockOptions.ads?.formats || '';
  const adGridProps = getAdGridProps({ isAdDisplayedOnFirstPosition, adStartLg, hasSliderSm, hasSliderMd });

  return {
    hasAd,
    isCbaAd,
    isAdDisplayedOnFirstPosition,
    adFormats,
    adGridProps,
  };
}

type SliderGridOptions = {
  hasSliderSm: boolean;
  hasSliderMd: boolean;
  hasAd?: boolean;
  isAdDisplayedOnFirstPosition: boolean;
  nrOfGridColumnsLg: number;
};

function createSliderGrid(
  items: GGrid['data']['items'],
  { hasSliderSm, hasSliderMd, hasAd, isAdDisplayedOnFirstPosition, nrOfGridColumnsLg }: SliderGridOptions,
): GGrid {
  return {
    type: 'grid',
    data: {
      items,
      gridColumns: hasSliderSm ? items.length : 1,
      gridColumnsMd: hasSliderMd ? items.length : 2,
      gridColumnsLg: hasAd ? nrOfGridColumnsLg - 1 : nrOfGridColumnsLg,
      gridAutoRows: 'min-content',
      hasInlinePadding: false,
      scroll: {
        breakpoints: {
          xs: hasSliderSm,
          sm: hasSliderSm,
          md: hasSliderMd,
          lg: false,
          xl: false,
        },
      },
    },
    gridProps: {
      column: '1 / -1',
      columnLg: hasAd ? getColumnLg(isAdDisplayedOnFirstPosition) : '1 / -1',
    },
  };
}
